import { useLocation } from "@reach/router"
import Cookie from "js-cookie"
import { createContainer } from "lib/unstated"
import { useEffect, useMemo, useState } from "react"
import useUserPreferences from "../hooks/useUserPreferences"
import { useIPLocation } from "./IPLocationProvider"

const ShopLocaleState = () => {
  const location = useLocation()
  const { getFavouriteLang } = useUserPreferences()
  const {
    country,
    loading: loadingCountry,
    locale: countryLocale,
  } = useIPLocation()

  const [locale, setLocale] = useState(
    Cookie.get("nzk-shop-locale") || countryLocale || getFavouriteLang()
  )

  const onLanguageChanged = l => {
    Cookie.set("nzk-shop-locale", l.value)
    Cookie.set("nzk-shop-locale-forced", "true")
    setLocale(l.value)
    if (typeof window === 'undefined') return
    window.postMessage(
      {
        event: "SHOP_LOCALE_CHANGED",
        payload: { value: l.value },
      },
      location.origin
    )
  }

  const LOCALE = useMemo(() => {
    let finalLocale = Cookie.get("nzk-shop-locale")
    if (!finalLocale && country) finalLocale = countryLocale
    if (!finalLocale && locale) finalLocale = locale
    if (!finalLocale && typeof window !== "undefined")
      finalLocale =
        navigator && navigator.language && navigator.language.toLowerCase()
    return finalLocale || "en-us"
  }, [country, locale])

  const manuallySet = useMemo(() => {
    return (
      Cookie.get("nzk-shop-locale") &&
      Cookie.get("nzk-shop-locale-forced") === "true"
    )
  }, [])

  const failedToDetectCountry = useMemo(() => {
    return !loadingCountry && !country
  }, [country, loadingCountry])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const lang = params.get("shopLang")
    if (lang) {
      onLanguageChanged({ value: lang })
    }
  }, [])

  useEffect(() => {
    if (!loadingCountry && LOCALE) {
      Cookie.set("nzk-shop-locale", LOCALE)
    }
  }, [country, loadingCountry, LOCALE])

  return {
    locale: LOCALE,
    setLocale,
    loadingCountry,
    onLanguageChanged,
    failedToDetectCountry,
    manuallySet,
  }
}

const ShopLocaleStateContainer = createContainer(ShopLocaleState)

export const ShopLocaleStateProvider = ShopLocaleStateContainer.Provider

export const useShopLocaleState = ShopLocaleStateContainer.useContainer
