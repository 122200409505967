import Select from "components/UI/Select"
import { Button } from "nzk-react-components"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useShopLocaleState } from "../../../../providers/ShopLocaleProvider"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  min-height: 300px;
  min-width: 350px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  padding: 35px 20px 15px 20px;
  border-radius: 14px;
  text-align: center;
`

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 30px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

interface IShopSelectCountryModal {
  countries: Strapi.Country[]
  dismiss: () => void
}

const ShopSelectCountryModal = (props: IShopSelectCountryModal) => {
  const [tempLocale, setTempLocale] = useState("en-us")
  const { locale, onLanguageChanged } = useShopLocaleState()

  const onCountrySelected = () => {
    onLanguageChanged({ value: tempLocale })
    props.dismiss()
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setTempLocale(
        navigator.language ? navigator.language.toLowerCase() : locale
      )
    }
  }, [])

  return (
    <Overlay onClick={props.dismiss}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <Title>Which country are you shopping from?</Title>
        <Select
          value={tempLocale}
          placeholder="Select your country"
          options={props.countries.map(c => ({
            value: c.locale,
            label: c.name || "",
          }))}
          onChange={value => setTempLocale(value)}
        />
        <Actions>
          <Button theme="primary" size="regular" onClick={onCountrySelected}>
            Submit
          </Button>
        </Actions>
      </Wrapper>
    </Overlay>
  )
}

export default ShopSelectCountryModal
