import useAsync from "./useAsync"

interface IIpTraceData {
  colo: string
  fl: string
  gateway: string
  h: string
  http: string
  ip: string
  loc: "GB" | "US" | string
  sni: string
  tls: string
  ts: string
  uag: string
  visit_scheme: string
  warp: string
}

const useIpTrace = () => {
  const {
    data: ipTraceInfo,
    execute: fetchLoc,
    loading,
  }: { execute: () => void; data: IIpTraceData; loading: boolean } = useAsync({
    initialData: {},
    immediate: true,
    asyncFunc: async () => {
      let lastLoc
      try {
        lastLoc = JSON.parse(localStorage.getItem("last-fetched-loc") || "{}")
      } catch (err) {
        console.error("Error parsing last loc")
      }
      if (lastLoc && Date.now() - lastLoc.timestamp > 3600) return lastLoc
      return new Promise(resolve => {
        fetch("https://www.cloudflare.com/cdn-cgi/trace")
          .then(res => res.text())
          .then(data => {
            const json = data
              .trim()
              .split("\n")
              .reduce((obj, pair) => {
                const pairs = pair.split("=")
                // eslint-disable-next-line
                obj[pairs[0]] = pairs[1]
                return obj
              }, {})
            localStorage.setItem(
              "last-fetched-loc",
              JSON.stringify({ ...json, timestamp: Date.now() })
            )
            resolve(json)
          })
          .catch(err => {
            console.error(err)
            resolve(null)
          })
      })
    },
  })

  return { loading, fetchLoc, ipTraceInfo }
}

export default useIpTrace
